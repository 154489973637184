import { render, staticRenderFns } from "./tPositiveResultList.vue?vue&type=template&id=dec997b2&"
import script from "./tPositiveResultList.vue?vue&type=script&lang=js&"
export * from "./tPositiveResultList.vue?vue&type=script&lang=js&"
import style0 from "./tPositiveResultList.vue?vue&type=style&index=0&id=dec997b2&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dec997b2')) {
      api.createRecord('dec997b2', component.options)
    } else {
      api.reload('dec997b2', component.options)
    }
    module.hot.accept("./tPositiveResultList.vue?vue&type=template&id=dec997b2&", function () {
      api.rerender('dec997b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tPositiveResult/tPositiveResultList.vue"
export default component.exports